<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center animate__animated animate__fadeInLeft">
        <aiot-logo />
        <span class="brand-text text-white text-center font-medium-5">
          {{ $t('name') }}
        </span>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register Image"
            style="height: 660px; width: 780px;"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 animate__animated animate__fadeInRight"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1 mb-3 x-text-bold" title-tag="h1">
            {{ $t('registerTitle') }}
          </b-card-title>
          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                :label="$t('username')"
                label-class="h4 x-text-bold"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    size="lg"
                    class="x-clear-input"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('placeholderUsername')"
                  />
                  <small v-if="errors[0]" class="text-danger">{用户名不能为空</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('email')"
                label-class="h4 x-text-bold"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    size="lg"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('placeholderEmail')"
                  />
                  <small v-if="errors[0]" class="text-danger">邮箱不能为空</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('password')"
                label-class="h4 x-text-bold"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge x-clear-input"
                      size="lg"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      :placeholder="$t('placeholderPassword')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">密码不能为空</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('privacy1')}}
                  <b-link>{{ $t('privacy2')}}</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                class="mt-2"
                block
                type="submit"
                :disabled="invalid || !status"
              >
                {{ $t('register') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-right mt-2">
            <span>{{ $t('hasAccount') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span class="font-weight-bolder">&nbsp;{{ $t('login') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--<div class="divider my-2 mt-4">-->
          <!--<div class="divider-text">-->
          <!--{{ $t('or') }}-->
          <!--</div>-->
          <!--</div>-->

          <!--<div class="auth-footer-btn d-flex justify-content-center">-->
          <!--<b-button-->
          <!--variant="google"-->
          <!--href="javascript:void(0)"-->
          <!--&gt;-->
          <!--<feather-icon icon="MailIcon" />-->
          <!--</b-button>-->
          <!--<b-button-->
          <!--variant="github"-->
          <!--href="javascript:void(0)"-->
          <!--&gt;-->
          <!--<feather-icon icon="GithubIcon" />-->
          <!--</b-button>-->
          <!--</div>-->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AiotLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle,
  // BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    AiotLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    // BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/bg-dark-regist.png'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/bg-dark-regist.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$xtoast.error('暂不开放注册，请联系管理员！')
          // useJwt.register({
          //   username: this.username,
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)
          //     this.$router.push('/')
          //   })
          //   .catch(error => {
          //     this.$refs.registerForm.setErrors(error.response.data.error)
          //   })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
